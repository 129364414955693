<template>
  <v-card>
    <v-row class="ma-0">
      <v-col
        cols="12"
        sm="7"
        class="total-profit-chart-col"
      >
        <v-card-title class="pt-2">
          Total Profit
        </v-card-title>
        <div v-if="loaded">
          <vue-apex-charts

            id="total-profit-chart"
            height="320"
            :options="chartOptions"
            :series="chartSeries"
          ></vue-apex-charts>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <v-card elevation="0">
          <!-- Title/Header -->
          <v-card-title class="pt-0 align-start flex-nowrap">
            <div>
              <p class="mb-0 text-2xl font-weight-bold">
                $482.85k
              </p>
              <small class="text-xs text--secondary text-no-wrap">Last month balance $234.40k</small>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n6 mt-n1"
            >
              <v-icon size="22">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text class="pt-5 pb-3">
            <!-- List -->
            <v-list
              two-line
              subheader
            >
              <!-- List Item: Profit -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="justify-center v-avatar-light-bg success--text"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="success"
                  >
                    {{ icons.mdiTrendingUp }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    $48,568.20
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Profit</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- List Item: Income -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  class="justify-center v-avatar-light-bg primary--text"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="primary"
                  >
                    {{ icons.mdiCurrencyUsd }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    $38,453.25
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Income</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- List Item: Expense -->
              <v-list-item class="pa-0">
                <v-list-item-avatar
                  color="primary"
                  class="justify-center v-avatar-light-bg secondary--text"
                  size="40"
                  rounded
                >
                  <v-icon
                    size="30"
                    color="secondary"
                  >
                    {{ icons.mdiChartBar }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-semibold">
                    $2,453.45
                  </v-list-item-title>
                  <v-list-item-subtitle>Total Expense</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <!-- Action Button -->
            <v-btn
              block
              color="primary"
              @click="getMongoChartData"
            >
              View Report
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd, mdiChartBar } from '@mdi/js'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { chartService, apiService } from '@/api/index'

export default {
    name: 'EcommerceTotalProfit',
    components: {
        VueApexCharts,
    },
    setup() {
        const $vuetify = getVuetify()

        const chartOptions = {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    columnWidth: '35%',
                    startingShape: 'rounded',
                    endingShape: 'rounded',
                },
            },
            xaxis: {
                categories: [2015, 2016, 2017, 2018, 2019, 2020, 2021],
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    formatter: value => kFormatter(value, 0),
                },
            },
            grid: {
                strokeDashArray: 7,
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            stroke: {
                curve: 'smooth',
                width: 6,
                lineCap: 'round',
                colors: ['#fff'],
            },
            responsive: [
                {
                    breakpoint: 1400,
                    options: {
                        plotOptions: {
                            bar: {
                                borderRadius: 10,
                                columnWidth: '45%',
                            },
                        },
                    },
                },
            ],
        }

        const chartData = [
            {
                name: 'PRODUCT A',
                data: [29000, 22000, 25000, 19000, 30000, 21000, 35000],
            },
            {
                name: 'PRODUCT B',
                data: [0, 16000, 11000, 15000, 0, 12000, 9000],
            },
            {
                name: 'PRODUCT C',
                data: [0, 0, 0, 14000, 0, 11000, 12000],
            },
        ]

        return {
            chartOptions,
            chartData,

            icons: {
                mdiDotsVertical,
                mdiTrendingUp,
                mdiCurrencyUsd,
                mdiChartBar,
            },
        }
    },
    data() {
        return {
            dataTable: [],
            chartSeries: [],
            loaded: false,
        }
    },
    async mounted() {
    // this.$root.$on('refresh-charts', () => {
    //   this.getMongoChartData()
    // })
    // await this.getMongoChartData()
    },
    methods: {
        async getMongoChartData() {
            this.loaded = false
            const token = await this.$auth.getTokenSilently()
            const body = {
                dateRange: this.dateRange,
                accNumbers: this.selectedPharmacies,
                groupCode: this.molecule,

                chartType: 'stackedColumnChart',
            }
            const pharmList = chartService.getChart(token, 'pharmacyListSelect')
            const molList = chartService.getChart(token, 'moleculeList')
            const table = apiService.getData(token, 'marketShareColumnChart', body)

            const [pharmacyListData, moleculeListData, dataTable] = await Promise.all([pharmList, molList, table])
            this.pharmacyListData = pharmacyListData
            this.moleculeListData = moleculeListData
            this.chartOptions.xaxis.categories = dataTable[0]
            this.dataTable = dataTable
            console.log(this.dataTable)
            this.chartSeries = this.dataTable[1]
            this.loaded = true
        },
    },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .total-profit-chart-col {
    @include ltr() {
      border-right: thin solid;
    }
    @include rtl() {
      border-left: thin solid;
    }
  }
}
@include theme--child(total-profit-chart-col) using ($material) {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-color: map-get($material, 'dividers');
  }
}
</style>
